.top-reasons {
  background-color: rgba(130, 204, 222, 0.1);
}

.aboutPetInsurance-topReasons-item1 {
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 24px;
}

.aboutPetInsurance-thingsToKeepInMind-chart {
  border: 1px solid #82ccde;
  border-radius: 10px;
  margin: 35px 17px;
  background-color: white;
  padding: 10px 20px 30px;
}

.aboutPetInsurance-thingsToKeepInMind-circle {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #82ccde;
  margin-top: 7px;
}
.aboutPetInsurance-topReasons-item {
  font: 1.05em OutfitExtraLight !important;
  letter-spacing: 0.00938em;
}
.aboutPetInsurance-topReasons-item {
  font: 1.05em OutfitExtraLight !important;
  letter-spacing: 0.00938em;
}

.aboutPetInsurance-thingsToKeepInMind-header,
.aboutPetInsurance-topReasons-header {
  font-weight: 600 !important;
  letter-spacing: 0.00938em;
}

@media only screen and (min-width: 1440px) {
  .top-reasons-container {
    padding: 0 3.8em;
  }

  .aboutPetInsurance-topReasons-item1 {
    padding: 3em 6em 3em 0;
  }

  .aboutPetInsurance-thingsToKeepInMind-item2 {
    padding-left: 2em;
    padding-right: 0;
  }

  .aboutPetInsurance-thingsToKeepInMind-chart {
    padding-bottom: 40px;
  }

  .aboutPetInsurance-thingsToKeepInMind-header,
  .aboutPetInsurance-topReasons-header {
    font-size: 1.1em !important;
  }
}
