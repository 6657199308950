.plan-summary {
  width: auto;
  line-height: 130%;
  margin: 15px;
}

.plan-summary hr {
  height: 1px;
  background-color: #82ccde;
  border: 0;
  margin: 15px 0;
}

.plan-summary .plan-header {
  height: 30px;
}

.plan-summary .plan-banner {
  background: #004e6f;
  border-radius: 8px 8px 0 0;
  padding: 5px;
  text-align: center;
  color: #fff;
  position: relative;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.plan-summary .plan-content {
  border-radius: 8px;
  border: 1px solid #82ccde;
  padding: 25px 12px 12px;
  margin-top: -10px;
  background-color: #ffffff;
}

.plan-summary img {
  display: block;
  margin: 25px auto;
  height: 43px;
}

.plan-summary img.nationwide-logo {
  height: 65px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.plan-summary .intro {
  text-align: left;
}

.plan-summary .bullets {
  min-height: inherit;
}

.plan-summary .extra {
  padding: 20px 0;
}

.plan-summary ul {
  display: block;
  list-style-type: disc;
  margin-top: 20px;
  padding-left: 5px;
}

.plan-summary li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  list-style: none;
  color: #070000;
}

.plan-summary li:before {
  content: "";
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  background: url(/public/img/cyan-bullet.svg) no-repeat left top;
  align-self: flex-start;
  margin-top: 5px;
}

.plan-summary .cta-button {
  margin: 0 auto 25px auto;
  width: 98%;
  margin-top: 25px;
}

.plan-summary .expand {
  text-align: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  height: 41px;
  margin-bottom: 5px;
}

.expand .nudger {
  height: 20px;
}

.expand.open .nudger {
  padding-top: 6px;
}

@media only screen and (min-width: 1440px) {
  .plan-summary {
    margin: 0;
  }

  .plan-summary .intro {
    text-align: center;
    min-height: 105px;
  }

  #nationwide.plan-summary .intro {
    padding-bottom: 1em;
  }

  #pumpkin.plan-summary .intro {
    padding-bottom: 1.25em;
  }

  .plan-summary .bullets {
    min-height: 228px;
  }

  .trupanion-extra-spacing {
    margin-top: 6.95em !important;
  }

  .pumpkin-extra-spacing {
    margin-top: 7.5em !important;
  }
}
