.how-it-works-background {
  background-color: rgba(130, 204, 222, 0.1);
}

.how-it-works {
  padding: 1.95em 1em 0.85em;
  color: #050000;
}

.how-it-works .MuiTypography-subtitle1 {
  line-height: 130%;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 1.5em;
}

.how-it-works .MuiTypography-subtitle2 {
  font-size: 1em;
  line-height: 1.15em;
}

.how-it-works .MuiTypography-body1 {
  line-height: 1.3em;
  margin-top: 0.6em;
}

.how-it-works .using-your-insurance-header {
  margin-top: 2.2em;
  font-size: 1.1em;
  font-weight: 500;
  margin-left: 0.85em;
}

.how-it-works .how-to-step {
  background: #ffffff;
  border: 1.5px solid #82ccde;
  border-radius: 8px;
  margin: 1.1em 0em 1em 1em;
  padding: 1em;
  padding-left: 0;
  display: flex;
  align-items: center;
}

.how-it-works .how-to-step .number {
  text-align: center;
  background: #ffffff;
  border: 1.5px solid #82ccde;
  border-radius: 25px;
  padding: 0.4em;
  margin: 0;
  height: 1em;
  width: 1em;
  top: 0.7em;
  left: -1.7em;
  color: #82ccde;
  line-height: 1em;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: -1.2em;
  font-size: 1.1em;
}

.how-it-works .step-content {
  padding-left: 1em;
}

.how-it-works .text {
  color: #61656b;
  margin-bottom: 0;
  font-size: 0.8em;
  font-weight: 300;
  line-height: 1.47em;
}

@media only screen and (min-width: 1440px) {
  .how-it-works {
    padding: 2.6em 0;
  }

  .how-it-works .MuiTypography-subtitle1 {
    color: rgba(0, 78, 111, 1);
    font-size: 2.28em;
    margin-top: 2.6em;
    letter-spacing: 0.043em;
    width: 60%;
    line-height: 0.98em;
    font-weight: 500;
  }
  .how-it-works .MuiTypography-subtitle2 {
    font-size: 1.45em;
    font-weight: 600;
    text-align: left;
    padding-top: 0em;
    letter-spacing: 0.019em;
    margin-top: 0.3em;
  }

  .how-it-works .MuiTypography-body1 {
    font-size: 1.25em;
    letter-spacing: 0.063em;
    line-height: 1.32em;
    padding-right: 1em;
    padding-bottom: 0.7em;
    margin-top: 1.1em;
    margin-right: 1.1em;
  }

  .how-it-works .how-to-step .text {
    margin-top: 0.7em;
    line-height: 1.25em;
    color: initial;
    letter-spacing: 0.02em;
    padding: 0;
  }
  .how-it-works .how-to-step .number {
    font-size: 1.5em;
    margin-left: -1em;
  }
}
