.plan-summaries {
  background-color: #f4faf8;
}

.plan-summaries .intro-logo {
  padding-left: 25px;
  padding-top: 15px;
  width: 9em;
}

.our-partners .plan-summaries .provider-cards {
  padding-bottom: 1em;
}

@media only screen and (min-width: 1440px) {
  .our-partners .plan-summaries {
    padding-top: 55px;
  }
  .our-partners .plan-summaries .provider-cards {
    margin: 45px auto 0;
    width: 1080px;
    padding-bottom: 2.5em;
  }
}
