.aboutPetInsuranceMainText-container {
  padding-left: 1.063em;
  padding-right: 1.063em;
}

.aboutPetInsuranceMainText-header1 {
  font-weight: 500 !important;
  color: #004e6f;
  letter-spacing: 0.0072em;
}

.aboutPetInsuranceMainText-header2 {
  font-weight: 500 !important;
  color: #004e6f;
  letter-spacing: 0em;
}

.aboutPetInsuranceMainText-mainDescription,
.aboutPetInsuranceMainText-description {
  font: 1.05em OutfitExtraLight !important;
  letter-spacing: 0.00938em;
}

.aboutPetInsurance-benefitsImageContainer {
  display: none;
  text-align: center;
}

.aboutPetInsurance-benefitsImage {
  width: 35%;
  margin-left: 2em;
}

@media only screen and (min-width: 1440px) {
  .aboutPetInsuranceMainText-header1 {
    text-align: center;
    font-size: 2.8em !important;
  }

  .aboutPetInsuranceMainText-header2 {
    font-size: 2.3em !important;
  }

  .aboutPetInsuranceMainText-mainDescription {
    text-align: center;
    font-size: 1.5em !important;
    padding-left: 15em;
    padding-right: 15em;
  }

  .aboutPetInsurance-benefitsContainer {
    padding-top: 40px;
  }

  .aboutPetInsuranceMainText-description {
    font-size: 1.2em !important;
    padding-right: 1.5em;
  }

  .aboutPetInsurance-benefitsTextContainer {
    margin-left: -3em !important;
  }

  .aboutPetInsuranceMainText-container {
    padding-bottom: 60px;
    padding-left: 0;
    padding-right: 0;
  }
  .aboutPetInsurance-benefitsImageContainer {
    display: initial;
  }
}
