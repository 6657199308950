table {
  border-spacing: 0;
  width: 100%;
}

th,
td {
  text-align: center;
  padding: 16px;
}

th:first-child,
td:first-child {
  text-align: left;
}

.planComparison-title {
  padding-top: 1em;
  padding-bottom: 0.2em;
  color: rgba(0, 78, 111, 1);
  font-size: 2.7em !important;
  font-weight: 500 !important;
  font-family: Gilroy !important;
  font-weight: 500;
}

.planComparison-container {
  margin-top: 2em;
  padding-left: 3em;
  padding-right: 3em;
  padding-bottom: 1em;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #82ccde;
}

.planComparison-providerHeader {
  padding-top: 40px;
  padding-bottom: 25px;
  font-family: Gilroy;
  font-weight: 500;
  color: #d0d0d0;
  font-size: 1.2em;
}

.planComparison-companyHeader {
  width: 20%;
  padding-left: 2em;
  padding-right: 2em;
}

.planComparison-companyImage {
  width: 100%;
  vertical-align: middle;
}

.planComparison-rowSectionContainer {
  border-top: 1px solid #82ccde;
}

.planComparison-rowSection {
  padding-top: 14px;
  padding-bottom: 14px;
  font-weight: 200;
  vertical-align: middle;
}

.icon {
  width: 15%;
  height: 15%;
}

.plan-comparison {
  background-color: white;
  padding-top: 1em;
  padding-bottom: 5em;
}

.planComparison-disclaimer {
  margin-top: 0.6em;
}

@media only screen and (min-width: 1440px) {
  tr:nth-child(even) {
    background: linear-gradient(
      to right,
      transparent 2%,
      rgba(130, 204, 222, 0.1) 50%,
      transparent 98%
    );
  }
}

@media only screen and (max-width: 1439px) {
  tr:nth-child(odd) {
    background: linear-gradient(
      to right,
      transparent 2%,
      rgba(130, 204, 222, 0.1) 50%,
      transparent 98%
    );
  }

  tr:nth-child(1) {
    background: none;
  }

  th,
  td {
    padding-left: 0;
    padding-right: 0;
    font-size: 0.85em !important;
  }

  .planComparison-title {
    padding-top: 0.3em;
    text-align: left !important;
    padding-left: 0.5em;
    font-size: 2em !important;
    font-family: Gilroy !important;
    font-weight: 500;
  }

  .planComparison-item-mobile {
    padding-left: 12px;
    padding-right: 12px;
  }

  .planComparison-container {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    border: none;
    margin-top: 0;
  }

  .planComparison-companyHeader {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }

  .planComparison-providerHeader {
    padding-bottom: 0;
    visibility: hidden;
  }

  .icon {
    width: 1.8em;
    height: 1.8em;
  }

  .planComparison-companyImage {
    width: 2.2em;
    margin: auto;
  }

  .trupanion-mobile {
    width: 2.5em;
  }

  .nationwide-mobile {
    width: 2.3em;
    margin-top: -0.7em;
  }

  .planComparison-rowSectionContainer {
    border-top: none;
    border-bottom: 1px solid #82ccde;
  }

  .planComparison-disclaimer {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}
