@media only screen and (min-width: 1440px) {
  section > .content {
    width: 1080px;
    padding-left: 180px;
    padding-right: 180px;
    margin-left: auto;
    margin-right: auto;
  }
  section > .content.no-gutter {
    width: 1440px;
    padding-left: 0;
    padding-right: 0;
  }
}
