.banner-section {
  background-color: #004e6f;
  color: white;
}

.banner-section .avoid-vet-bills {
  padding-top: 1.125em;
  background-image: url(/public/img/vet_with_aussie-sm.png),
    url(/public/img/blue_spotlight.svg);
  background-position: right 0.1em bottom 3em, right -3.8em bottom 2.7em;
  background-size: 230px, 321px;
  background-repeat: no-repeat;
}

.banner-section .prepare-for-vet {
  padding-top: 0.7em;
  background-image: url(/public/img/shutterstock_aussie-sm.png),
    url(/public/img/blue_spotlight.svg);
  background-position: right -0.1em bottom 5.5em, right -3.8em bottom 2.7em;
  background-size: 235px, 321px;
  background-repeat: no-repeat;
}

.banner-section h3 {
  font-weight: 500;
  font-size: 1.7em;
  margin: 0.5em 1em;
  text-align: center;
  letter-spacing: 0.02em;
  line-height: 1.2em;
}

.banner-section .avoid-vet-bills h3 {
  margin-bottom: 0.3em;
}

.banner-section h6 {
  font-size: 0.95em;
  font-weight: 400;
  letter-spacing: 0.025em;
  line-height: 1.4em;
}

.banner-section .avoid-vet-bills h6 {
  letter-spacing: 0.035em;
  line-height: 1.45em;
}

.banner-section .subtext-wrapper {
  background-image: linear-gradient(90deg, #004e6f 0%, #004e6f00 64.35%);
  filter: drop-shadow(0px 10px 40px rgba(0, 0, 0, 0.25));
  border-radius: 15px;
}

.banner-section .avoid-vet-bills .subtext-wrapper {
  margin-left: 0.9em;
  padding: 0.8em 0.9em;
  width: 44%;
}

.banner-section .prepare-for-vet .subtext-wrapper {
  margin-top: 1em;
  margin-left: 1.5em;
  padding: 1.1em;
  width: 40%;
}

.banner-section .background-fade {
  padding-bottom: 2.1em;
  background-image: linear-gradient(
    to top,
    rgb(0 78 111) 120px,
    transparent 200px
  );
  background-repeat: no-repeat;
}

.banner-section .avoid-vet-bills .background-fade {
  padding-top: 8.1em;
  background-position: right 0em top 1em;
}

.banner-section .prepare-for-vet .background-fade {
  padding-top: 3.5em;
  background-position: right 0em top 1em;
}

.banner-section .cta-button {
  margin: 1.5em auto;
  width: 85%;
  padding: 0.9em 1.8em;
  letter-spacing: 0.04em;
  font-size: 1.1em;
  font-weight: 600;
}

.banner-section .logo {
  display: inline-block;
  vertical-align: top;
  width: 33%;
}
.banner-section .logo img {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.banner-section .logo.pumpkin {
  padding-top: 0.6em;
  width: 22%;
  padding-left: 4%;
}

.banner-section .logo.trupanion {
  padding-top: 0.75em;
  width: 29%;
  padding-left: 6%;
}

.banner-section .logo.nationwide {
  padding-top: 0.25em;
  width: 28%;
  padding-left: 6%;
}

@media only screen and (min-width: 1440px) {
  .banner-section .avoid-vet-bills,
  .banner-section .prepare-for-vet {
    padding-right: 43.2em;
    padding-left: 12.5em;
    padding-top: 0 !important;
  }

  .banner-section .avoid-vet-bills {
    background-image: url(/public/img/vet_with_aussie.png),
      url(/public/img/blue_spotlight.svg);
    background-position: right 16.6em bottom -10.7em, right 10em bottom -13em;
    background-size: 415px, 590px;
    height: 500px;
  }

  .banner-section .prepare-for-vet {
    background-image: url(/public/img/shutterstock_aussie.png),
      url(/public/img/blue_spotlight.svg);
    background-position: right 16.8em bottom -7.7em, right 9em bottom -12.6em;
    background-size: 425px, 590px;
    height: 480px;
  }

  .banner-section h3 {
    font-size: 2.85em;
    margin: 0;
    letter-spacing: 0.063em;
  }

  .banner-section .avoid-vet-bills h3 {
    padding-top: 1.4em;
    text-align: initial;
  }

  .banner-section .prepare-for-vet h3 {
    padding-top: 1.6em;
    text-align: initial;
    line-height: 1.1em;
  }

  .banner-section .subtext-wrapper {
    padding: 0 !important;
    margin-top: 1.2em !important;
    margin-left: 0em !important;
    width: 77% !important;
    background-image: none;
    filter: none;
    border-radius: 0;
  }

  .banner-section .prepare-for-vet .subtext-wrapper {
    margin-top: 0.75em !important;
    width: 90% !important;
  }

  .banner-section .subtext-wrapper h6 {
    font-size: 1.5em;
    font-weight: 300;
    letter-spacing: 0.045em;
    line-height: 1.15em;
  }

  .banner-section .background-fade {
    background-image: none !important;
    padding-top: 0 !important;
    margin-top: 1.6em;
  }

  .banner-section .background-fade .cta-button {
    padding: 0.9em 1.8em;
    letter-spacing: 0.05em;
    font-weight: 500;
    width: 52.5%;
    font-size: 1.15em;
  }

  .banner-section .avoid-vet-bills .background-fade .cta-button {
    margin: 0 0 1.45em;
  }

  .banner-section .prepare-for-vet .background-fade .cta-button {
    margin: 0 0 1.1em;
  }

  .banner-section .logos {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 76%;
  }

  .banner-section .logo {
    display: inline-block;
    vertical-align: top;
    width: 33%;
    height: 1.5em;
    padding: 0;
  }

  .banner-section .logo img {
    display: block;
    margin: auto;
    max-height: 100%;
    max-width: 100%;
  }

  .banner-section .logo.pumpkin {
    padding-top: 0.95em;
    height: 1.55em;
    width: auto;
    padding-right: 1.86em;
    padding-left: 0;
  }

  .banner-section .logo.trupanion {
    padding-top: 1.05em;
    height: 1.56em;
    width: auto;
    padding-right: 1.75em;
    padding-left: 0;
  }

  .banner-section .logo.nationwide {
    height: 2.4em;
    width: auto;
    padding-top: 0.5em;
    padding-left: 0;
  }
}
