.meetOurPartners {
  padding-left: 24px;
  padding-right: 24px;
  margin-top: -60px;
  position: relative;
}

.meetOurPartners .enclosure {
  border: 1px solid #f3f3f3;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  padding: 15px 14px 10px;
  background-color: white;
}

.meetOurPartners .sectionContainer {
  padding-left: 8px;
  padding-right: 8px;
}

.meetOurPartners .title {
  color: #d0d0d0;
  font-weight: 500;
  padding-left: 0.5em;
  padding-bottom: 0.2em;
}

.meetOurPartners .logo {
  width: 100%;
}

.meetOurPartners .ctaArrowContainer {
  text-align: right;
}

.meetOurPartners .ctaArrow {
  width: 1.5em;
}

.meetOurPartners .sectionContainer {
  cursor: pointer;
}

.meetOurPartners .spacer {
  width: 100%;
  height: 1px;
  background-color: rgba(235, 235, 235, 1);
  margin-top: 14px;
  margin-bottom: 14px;
}

.meetOurPartners .spacer-2 {
  display: none;
}

.meetOurPartners .mostComprehensive {
  font-weight: 500;
  line-height: 10px;
  font-size: 0.5em;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 25px;
  text-align: center;
  color: white;
  border: 1px solid;
  border-radius: 20px;
  background-color: rgba(0, 78, 111, 1);
  padding: 5px;
}

@media only screen and (min-width: 1440px) {
  .meetOurPartners {
    position: relative;
    border: 1px solid #f3f3f3;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
    background-color: white;
    padding-left: 0;
    padding-right: 0;
  }

  .meetOurPartners .enclosure {
    padding: 0;
    height: 5.5em;
    border-width: 0px;
    box-shadow: none;
    background-color: none;
  }

  .meetOurPartners .spacer {
    width: 1px;
    height: 50%;
    align-items: center;
    background-color: rgba(235, 235, 235, 1);
    margin-top: auto;
    margin-bottom: auto;
  }

  .meetOurPartners .spacer-2 {
    display: initial;
  }

  .meetOurPartners .mostComprehensive {
    display: none;
  }

  .meetOurPartners .title {
    color: #d0d0d0;
    margin-top: 0.4em;
    font-size: 1.05em;
    letter-spacing: 0.00938em;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 0;
  }

  .meetOurPartners .sectionContainer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .meetOurPartners .sectionContainer:hover {
    background-color: #82ccde1a;
  }
}
