@font-face {
  font-family: Gilroy;
  font-weight: 600;
  src: url("/public/fonts/Gilroy-Bold.otf") format("opentype");
}

@font-face {
  font-family: Gilroy;
  font-weight: 500;
  src: url("/public/fonts/Gilroy-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: Gilroy;
  font-weight: 400;
  src: url("/public/fonts/Gilroy.otf") format("opentype");
}

@font-face {
  font-family: Gilroy;
  font-weight: 200;
  src: url("/public/fonts/Gilroy-Light.otf") format("opentype");
}

@font-face {
  font-family: OutfitExtraLight;
  src: url("/public/fonts/Outfit-ExtraLight.ttf") format("truetype");
}

body {
  margin: 0;
  background-color: #e5e5e5;
  font-family: Gilroy;
  font-weight: 400;
}

hr {
  display: block;
  border: 0;
  border-top: 2px solid #eaeaea;
  margin: 10px 0;
  padding: 0;
}

.mobile-only {
  display: inherit;
}

.desktop-only {
  display: none;
}

.MuiContainer-root {
  background-color: #ffffff;
}

.container-padding {
  padding-bottom: 25px;
}

.base-header-font {
  font-weight: 600 !important;
}

.base-paragraph-font {
  font-weight: 100 !important;
}

.header-1-spacing {
  padding-top: 25px;
}

.header-2-spacing {
  padding-top: 15px;
  padding-bottom: 5px;
}

.paragraph-spacing {
  padding-top: 10px;
}

.margin-top-and-bottom-small {
  margin-top: 20px;
  margin-bottom: 20px;
}

.spacer {
  height: 24px;
  background-color: #f7f7f7;
}

.iconTitleDesc {
  padding-top: 15px;
  padding-bottom: 15px;
}

.iconTitleDesc-imageContainer {
  padding-top: 10px;
}

.iconTitleDesc-image {
  width: 7em;
  display: block;
  margin: auto;
  padding-top: 10px;
}

.iconTitleDesc-whenTheyreSick {
  width: 4em;
  height: 4em;
  display: block;
  margin: auto;
  padding-top: 10px;
}

.iconTitleDesc-whenTheyreHurt {
  width: 5em;
  height: 5em;
  display: block;
  margin: auto;
  padding-top: 10px;
}

.iconTitleDesc-whenTheyreHealthy {
  width: 5.5em;
  height: 5.5em;
  display: block;
  margin: auto;
  padding-top: 10px;
}

.iconTitleDesc-title {
  font-weight: 400 !important;
}

.iconTitleDesc-description {
  font-weight: 100 !important;
}

.faq-description {
  padding-top: 10px;
}

.pumpkinHelp-description {
  padding-top: 10px;
  padding-bottom: 20px;
}

.aboutPuppyInsuranceSection-image {
  width: 4.5rem;
}

.aboutPuppyInsuranceSection-title {
  padding-top: 5px;
}

.header-dogImageContainer {
  text-align: right;
  margin-top: 20px;
}

.header-dogImage {
  width: 17em;
  margin-top: -20px !important;
}

.meetPumpkin-container {
  display: inline-block;
  margin-top: -3em !important;
}

.padding-top-extra-small {
  padding-top: 5px;
}

.padding-top-small {
  padding-top: 10px;
}

.padding-top-medium {
  padding-top: 20px;
}

.padding-top-large {
  padding-top: 35px;
}

.padding-top-extra-large {
  padding-top: 50px;
}

.hyperlink {
  font-weight: 500 !important;
  color: #4265ff;
  text-decoration: none;
}

a:link {
  text-decoration: none;
}

.quoteBlock {
  text-align: center;
  padding: 30px 40px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-top: 24px;
}

.quoteBlock-header {
  font-size: 1.3rem !important;
}

.quoteBlock-text {
  font-weight: 600 !important;
  font-size: 1.1rem !important;
  line-height: 1.7rem !important;
}

.quoteBlock-signatureContainer {
  margin-top: 15px;
}

.quoteBlock-signature {
  font-weight: 100 !important;
  font-style: italic;
}

.quoteBlock-signature:last-child {
  margin-top: 3px !important;
}

.quoteBlock-image {
  margin-top: -5px;
  padding-bottom: 10px;
}

.meetPumpkin-text {
  float: left;
}

.pumpkinLogo {
  display: block;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 10px;
}

.pumpkinHighlightAbove {
  background-color: white;
  padding: 30px 20px 20px;
}

.pumpkinHighlight {
  background-color: #f4f7ff;
  border-radius: 10px;
  padding: 20px 0;
  margin-top: 15px !important;
}

.pumpkinHighlight-pawprint {
  width: 35%;
  display: block;
  margin: auto;
}

.pumpkinHighlight-itemSpacing:not(:last-child) {
  padding-bottom: 22px !important;
}

.pumpkinCta-text {
  padding: 20px;
}

.pumpkinCta-hyperlinkText {
  font-weight: 400 !important;
  color: #757575;
  text-decoration: none;
  margin: 0 !important;
  cursor: pointer;
  background-color: white;
  font-size: 0.85em !important;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 15px;
}

.pumpkinCta-learnMore {
  font-weight: 600 !important;
  color: #4a6bff;
  text-decoration: none;
  margin: 0 !important;
  cursor: pointer;
  box-shadow: none;
  border: none;
  background-color: white;
}

.pumpkinCta-hyperlinkText-footer {
  font-weight: 600 !important;
  color: #4a6bff;
  text-decoration: none;
  cursor: pointer;
  box-shadow: none;
  border: none;
  background-color: #f7f7f7;
  font-size: 0.9em !important;
  margin-top: 5px !important;
  padding-left: 0 !important;
}

.pumpkinCta-openLinkLogo {
  width: 50%;
}

.pumpkinCta-openInNewTab {
  margin-left: 5px;
}

.tooltip-Icon {
  float: left;
}

.tooltip-Title {
  float: left;
  font-size: 0.9rem !important;
  font-weight: 100 !important;
  padding-left: 8px;
  color: #4a6bff;
}

.tooltip-container {
  display: inline-block;
  box-shadow: none;
  border: none;
  background-color: white;
}

.tooltip-information {
  font-weight: 100 !important;
  padding-left: 30px;
  padding-top: 5px;
  color: #757575;
}

.cta-image {
  display: block;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 11em;
}

.cardDropdown-spacing {
  margin-bottom: 25px !important;
}

.cardDropdown-container {
  box-shadow: 0 0 5px -1px #00000035 !important;
  padding: 1.1em;
  border-radius: 10px;
  /*
  0px ​0px 5px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%
   */
}

.cardDropdown-title {
  font-weight: 400 !important;
  font-size: 18px !important;
}

.cardDropdown-button {
  color: #4a6bff !important;
  font-weight: 400 !important;
  margin-top: 15px !important;
}

.cardDropdownOption-container {
  padding-top: 30px;
}

.cardDropdownOption-imgContainer {
  text-align: center;
}

.cardDropdownOption-textContainer {
  margin: auto !important;
}

.cardDropdownOption-text {
  font-weight: 400 !important;
  font-size: 16px !important;
}

.cardDropdown-expandIcon {
  transform: rotate(180deg);
  transition-duration: 0.8s;
  transition-property: transform;
}

.cardDropdown-paddingTop {
  margin-top: 20px;
}

.cardDropdownOption-img {
  height: 2.8em;
  width: 2.8em;
}

.footer-hr {
  padding-bottom: 10px;
}

.footer-section {
  margin-bottom: 5px !important;
}

.businessInsider-container {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #4e6ef6;
  border-radius: 10px;
}

.businessInsider-logo {
  width: 14rem;
  margin: auto;
  display: block;
  padding-top: 10px;
}

.businessInsider-text {
  font-weight: 600 !important;
  color: white;
  text-align: center;
  padding: 15px 50px 20px;
  font-size: 1.3rem !important;
}

.businessInsider-asterisk {
  font-size: 0.7rem;
  vertical-align: top;
  display: inline-block;
  padding-top: 2px;
}

.petInsuranceWorthIt-asterisk {
  font-size: 0.7rem;
  vertical-align: top;
  display: inline-block;
}

.petInsuranceWorthIt-title {
  font-weight: 400 !important;
  font-size: 1.1em !important;
  line-height: 1.5rem !important;
  padding-top: 20px;
}

.topReasons-container:nth-child(2) {
  padding-top: 20px !important;
}

.thingsToKeepInMind-container {
  padding: 10px 20px 10px 20px;
  border: 1px solid #dddddd;
  border-radius: 10px;
  margin-top: 24px;
}

.thingsToKeepInMind-title {
  font-weight: 400 !important;
  font-size: 1em !important;
  line-height: 1.5rem !important;
  margin-top: 5px !important;
  margin-bottom: 15px !important;
}

.thingsToKeepInMind-break {
  border: 1px solid #4469da;
}

.petInsuranceCost-image {
  width: 12em;
}

.petInsuranceCost-text {
  font-weight: 600 !important;
  font-size: 1.1em !important;
}

.petInsuranceWorthIt-circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 1px solid red;
}

.recommendationCta-button {
  display: block;
  border-radius: 50px;
  border: 0;
  padding: 12px 25px;
  background-color: #4a6bff;
  color: #ffffff;
  cursor: pointer;
  margin: 25px auto 20px;
}

.healthierPets-title {
  margin-top: -30px !important;
}

.ourPartners-mainImage {
  display: block;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 20px;
  width: 18em;
}

.lifetimeCostItem-img {
  width: 5em;
}

.lifetimeCostItem-title {
  font-weight: 400 !important;
  font-size: 1em !important;
}

.lifetimeCostItem-sectionContainer {
  text-align: center;
}

.lifetimeCostItem-price {
  font-weight: 600 !important;
  font-size: 1.1em !important;
  color: #4469da;
}

.lifetimeCostItem-finePrint {
  font-weight: 400 !important;
  color: #757575;
  text-decoration: none;
  margin: 0 !important;
  cursor: pointer;
  background-color: white;
  font-size: 0.85em !important;
  padding-top: 5px;
}

.accolades-text {
  font-weight: 400 !important;
  line-height: 1.4em !important;
}

.accolades-subtext {
  font-weight: 400 !important;
  font-size: 0.9em !important;
}

.accolades-container {
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (max-width: 600px) {
  .businessInsider-text {
    padding: 20px;
  }

  .petInsuranceCost-image {
    width: 9em;
  }

  .petInsuranceCost-text {
    font-size: 0.9em !important;
    padding-left: 12px;
  }
}

@media only screen and (max-width: 1439px) {
  .MuiContainer-maxWidthLg {
    max-width: 600px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .mobile-only {
    display: none;
  }

  .desktop-only {
    display: inherit;
  }
}
