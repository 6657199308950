.our-partners h2 {
  font-size: 3em;
  font-weight: 500;
  font-size: 46px;
  letter-spacing: 1.5px;
  line-height: 50px;
  color: #004e6f;
  text-align: center;
  font-weight: 600;
}

.our-partners h5 {
  text-align: center;
  font-weight: 200;
}
