.MuiAppBar-root {
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%) !important;
}

.container-tabPanel {
  background-color: #f4fafc;
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.mainPage-tabPanel-ctaArrow {
  width: 1.4em;
  margin-left: 0.4em;
}
.header-image {
  display: none;
}

.tabs .MuiTabs-indicator {
  background-color: #89d0e6;
  height: 10%;
  display: block;
  border-radius: 1em 1em 0 0;
}

.tabs .MuiTab-root {
  font-family: Gilroy;
  font-weight: 400;
  text-transform: none;
  color: #222222;
  font-size: 1.1em;
  min-height: 3.157em;
  padding: 0;
}

.MuiToolbar-root {
  min-height: 2.572em;
  padding: 0 1.75em;
}

.Mui-selected {
  color: #004e6f !important;
}

.MuiTabs-root {
  min-width: 100%;
  font-size: 0.9em !important;
}

.tabs .MuiTab-root.explore-plans {
  font-weight: 500;
}

.header-image-container {
  max-width: 0px;
  max-height: 3.7em;
  padding-top: 0;
}

.header-image {
  width: 8rem;
  cursor: pointer;
}

.MuiTab-root.divider {
  padding: 0;
  min-width: 1px;
  max-width: 1px;
  margin: auto 1.35em;
  background-color: #dadada;
  min-height: 2em;
}

@media only screen and (min-width: 1440px) {
  .MuiToolbar-root {
    padding: 0 !important;
  }

  .MuiTabs-root {
    min-width: 50%;
  }

  .tabs .MuiTabs-flexContainer {
    width: 70% !important;
  }

  .tabs .MuiTab-root {
    padding: 0.2em;
    font-size: 1.3em;
    min-height: 3.419em;
  }
  .tabs .MuiTab-root.divider {
    padding: inherit;
    min-height: 50%;
    margin: auto 1em;
  }

  .tabs .MuiTabs-scroller {
    display: flex;
    justify-content: flex-end;
  }

  .header-image-container {
    max-width: auto !important;
    padding-top: 0.53em;
    padding-left: 0.6em;
  }

  .header-image {
    display: initial;
  }
}
