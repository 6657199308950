.footer {
  background-color: #545454;
}

.footer .content {
  justify-content: space-between;
  color: #ffffff;
}

.footer .links {
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.footer img {
  padding: 20px 30px;
}

.footer .copy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.25em;
  line-height: 130%;
  padding: 20px 35px 20px 0;
  font-weight: 200;
  letter-spacing: 0.06em;
  text-align: end;
}

.footer .logo {
  padding-top: 10px;
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 1440px) {
  .footer {
    padding: 0;
  }
  .footer img {
    padding: 20px 0px;
  }
  .footer .copy {
    padding: 20px 0px;
  }

  .footer .links {
    padding: 0;
  }
}
