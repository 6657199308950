.insurance-cost {
  margin-top: 1.8em;
  margin-bottom: 2.4em;
  padding: 0 1em;
  color: #050000;
}

.insurance-cost .MuiTypography-subtitle1 {
  line-height: 130%;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-size: 1.5em;
  width: 75%;
}

.insurance-cost .MuiTypography-body1 {
  padding-top: 0.55em;
  padding-bottom: 1em;
  line-height: 1.32em;
}

.insurance-cost .body-disclaimer {
  padding-top: 0em;
  padding-bottom: 1em;
  width: 53%;
  font-size: 0.8em;
  line-height: 1.43em;
  letter-spacing: 0.01em;
}

.insurance-cost .average-cost {
  border: 2px solid #82ccde;
  box-sizing: border-box;
  border-radius: 8px;
  width: 75%;
  margin-top: 0.5em;
  padding: 1em 1.2em;
  margin-right: 6em;
}

.insurance-cost .cost {
  font-size: 1.7em;
  color: #82ccde;
  font-weight: 500;
  padding: 0;
  letter-spacing: 0.03em;
  line-height: 1em;
}

.insurance-cost .cost-disclaimer {
  padding: 0;
}

.insurance-cost .source {
  line-height: 1.5em;
  font-size: 0.8em;
  color: #b6b6b6;
  letter-spacing: 0.004em;
  padding-bottom: 0;
}

.insurance-cost .average-cost {
  position: relative;
}

.insurance-cost .average-cost img {
  height: 242px;
  margin-top: -12em;
  position: absolute;
  right: -5.7em;
}

@media only screen and (min-width: 1440px) {
  .insurance-cost {
    width: auto !important;
    max-height: 33em;
    margin: 1.8em 0 3.3em;
    padding: 0 0.5em;
  }
  .insurance-cost .MuiTypography-subtitle1 {
    color: rgba(0, 78, 111, 1);
    font-size: 2.3em;
    margin-top: 1.15em;
    letter-spacing: 0.03em;
    line-height: 1em;
  }

  .insurance-cost .MuiTypography-body1 {
    font-size: 1.25em;
    letter-spacing: 0.063em;
    line-height: 1.32em;
    padding-right: 1em;
    padding-bottom: 0.7em;
    padding-top: 0.95em;
  }
  .insurance-cost .body-disclaimer {
    font-size: 1em;
    padding-top: 0;
    width: 100%;
    letter-spacing: 0.082em;
  }
  .insurance-cost .average-cost {
    margin: 9.4em 0.7em 4em 0;
    padding-left: 4.5em;
    border-radius: 8px;
  }

  .insurance-cost .average-cost .cost {
    font-size: 2.2em;
    color: #004e6f;
    padding-top: 0.45em;
    padding-bottom: 0;
  }

  .insurance-cost .average-cost .cost-disclaimer {
    font-size: 1.25em;
    letter-spacing: 0.063em;
    line-height: 1.32em;
    padding: 0;
    margin-top: -0.2em;
  }
  .insurance-cost .average-cost .source {
    font-size: 0.9em;
    line-height: 1.5em;
    padding-top: 0.8em;
    margin-bottom: 0.5em;
  }
  .insurance-cost .average-cost img {
    margin: 0;
    height: 384px;
    margin-top: -19.5em;
    margin-left: -15.2em;
    right: auto;
  }
}
