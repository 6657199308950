.cta-button {
  display: block;
  border-radius: 50px;
  border: 0;
  padding: 12px 25px;
  background-color: #ffce34;
  color: #000000;
  cursor: pointer;
  font-size: 1.15em;
  font-weight: 500;
  font-family: "Gilroy";
}

.cta-button:hover {
  background-color: #dbb32d;
}
