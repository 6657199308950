.learn-more {
  background: #f2fafc;
  padding: 3em 2em;
  font-weight: 600;
}

.learn-more h3 {
  font-size: 1.25em;
  font-weight: 600;
  text-align: center;
}

.learn-more .cta-button {
  margin: 25px auto;
  width: 100%;
}

@media only screen and (min-width: 1440px) {
  .learn-more {
    padding: 2em 0;
  }

  .learn-more .wrapper {
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    width: 1080px;
    margin: 0 auto;
    padding: 20px 0;
    align-items: center;
  }

  .learn-more h3 {
    margin-left: 40px;
    font-weight: 600;
  }

  .learn-more .cta-button {
    margin: 0;
    width: 300px;
    margin-right: 40px;
    padding: 17px;
  }
}
