.faq {
  background: #f2fafc;
  padding: 2em;
  text-align: left;
}

.faq h2 {
  font-weight: 500;
  font-size: 1.87em;
  line-height: 1em;
  letter-spacing: 0.015em;
}

.faq .intro {
  margin: 12px 4px 15px;
  font-family: "Gilroy";
  letter-spacing: 0.00098rem;
  line-height: 1.32em;
}

.faq .collapsible {
  background: #ffffff;
  border-radius: 5px;
  line-height: 135%;
  padding: 10px;
  margin-top: 20px;
}

.faq .collapsible.open {
  color: #004e6f;
}

.faq .trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
}

.faq .trigger svg {
  width: 14px;
  margin-left: 15px;
  flex-shrink: 0;
}

.faq .answer {
  margin: 10px 0 5px;
  color: #070000;
}

.faq .questions-section {
  padding: 0px;
}

@media only screen and (min-width: 1440px) {
  .faq {
    padding: 5em 0em 2em;
  }
  .faq h2,
  .faq .intro {
    text-align: center;
    margin: 0 auto;
    width: 880px;
  }
  .faq h2 {
    font-size: 2.25em;
    letter-spacing: 0.0485em;
    color: #004e6f;
  }
  .faq .intro {
    margin-top: 13px;
    font-size: 1.26em;
    line-height: 1.2em;
    letter-spacing: 0.06em;
  }
  .faq .column {
    width: 524px;
    margin: 8px;
  }
  .faq .questions {
    margin: 25px auto 0;
    width: 1080px;
  }
  .faq .questions > .MuiGrid-item {
    max-width: 49%;
  }
  .faq .trigger {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    align-items: center;
    height: 40px;
  }
  .faq .collapsible {
    box-shadow: none;
  }

  .faq .questions-section {
    padding: 16px;
  }
}
