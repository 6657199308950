.disclaimers-container {
  background-color: #f2fafc;
  padding: 2em;
  padding-bottom: 4em;
}

.disclaimers-grid {
  border: 1px solid #82ccde;
  border-radius: 10px;
  background-color: white;
  padding: 1em 1em 1em 1em;
}

.disclaimers-licenseInfo-link {
  cursor: pointer;
  font-weight: bold;
  color: #004e6f !important;
}

.disclaimers-licenseInfoModal-intro {
  vertical-align: baseline;
  font-size: 1rem;
  font-family: Gilroy;
  font-weight: 400;
  line-height: 1.5;
  margin-top: 1em;
  margin-bottom: 1em;
}

.disclaimers-licenseInfoModal-title {
  padding-top: 10px;
}

.disclaimers-licenseInfoModal-stateLine {
  line-height: 1.5;
}

.disclaimers-licenseInfoModal-grid {
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 1em;
}

.disclaimer-spacing {
  padding-top: 20px;
  font-size: 14px !important;
}

.disclaimer-link-section {
  font-size: 14px !important;
  padding-top: 10px;
}

.disclaimer-link {
  color: white;
  text-decoration: underline !important;
}

.disclaimer-hr {
  padding-bottom: 10px;
}

.disclaimer-container {
  background-color: #f7f7f7 !important;
  padding-bottom: 20px;
}

@media only screen and (min-width: 1440px) {
  .disclaimers-container {
    padding: 2em 0em 4em;
  }

  .disclaimers-grid {
    padding: 2em 2em 2em 2em;
  }
}
