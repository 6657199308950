.aboutPetInsuranceHero-layout {
  background-color: #004e6f;
  overflow: hidden;
}

.aboutPetInsuranceHero-episLogo {
  padding-left: 25px;
  padding-top: 15px;
  width: 9em;
}

.aboutPetInsuranceHero-imageContainer {
  width: 100%;
  height: 24em;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
}

.aboutPetInsuranceHero-title {
  color: white;
  font-weight: 600 !important;
}

.aboutPetInsuranceHero-subtitle {
  color: white;
  padding-top: 10px;
  font: 1.2em OutfitExtraLight !important;
  letter-spacing: 0.00938em;
}

.aboutPetInsuranceHero-textContainer {
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
}
.aboutPetInsuranceHero-image-wrap {
  position: relative;
  height: 404px;
}

.aboutPetInsuranceHero-image {
  background-image: url(/public/img/hero-spotlight-sm.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: auto 384px;
  width: 600px;
  height: 100%;
  left: 55%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media only screen and (min-width: 1440px) {
  .aboutPetInsuranceHero-episLogo {
    display: none;
  }

  .aboutPetInsuranceHero-title {
    color: white;
    font-weight: 600 !important;
    font-size: 4.5em !important;
  }

  .aboutPetInsuranceHero-subtitle {
    color: white;
    padding-top: 10px;
    padding-bottom: 8.29em;
    font: 1.6em OutfitExtraLight !important;
  }

  .aboutPetInsuranceHero-textContainer {
    padding-right: 25px;
    padding-left: 12em;
    padding-top: 6em;
  }
  .aboutPetInsuranceHero-image-wrap {
    height: auto;
  }

  .aboutPetInsuranceHero-image {
    background-image: url(/public/img/hero.png),
      url(/public/img/blue_spotlight.svg);
    background-repeat: no-repeat;
    background-position: left -41% bottom 0%, right 100% bottom 111%;
    background-size: 825px, 1050px;
    width: 1050px;
    height: 100%;
    top: 0;
    left: -1%;
    position: relative;
    transform: none;
  }
}
